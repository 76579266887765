*{
  margin: 0;
  padding: 0;
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}


.relaxmood {
  background-image: url(images/SpongueChill.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 1.7; /* Adjust the opacity level as desired */
}
.relaxmood h1{
  margin-top: -100px;
}
.relaxmood p{
  color:white;
}
.pricing-background{
  background-image: url(images/Brainbulb.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.marquee-container {
overflow: hidden;
}

.marquee {
display: flex;
animation: marquee-animation 5s linear infinite;
}

.marquee img {
flex-shrink: 0;
}



@keyframes marquee-animation {
0% {
  transform: translateX(0%);
}
100% {
  transform: translateX(-100%);
}
}
/* Add this to your CSS file */
@keyframes jump {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-0.5cm);
  }
}

.create-btn {
  animation: jump 3s infinite ease-in-out;
}
.nav-links.active{
  color: #59844F;
}

/* Add these styles to your CSS file */
@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.slide-left {
  animation: slideInFromLeft 1s ease-out forwards;
}

.slide-right {
  animation: slideInFromRight 1s ease-out forwards;
}


/* Add this CSS to your stylesheet */
@keyframes popUp {
  from {
    opacity: 0;
    transform: translateY(300px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}


.visible {
  animation: popUp 1s forwards;
}

/* Add this CSS to your stylesheet */
@keyframes popDown {
  from {
    opacity: 0;
    transform: translateY(-300px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.visible-pop-down {
  animation: popDown 1s forwards;
}


@media only screen and (max-width: 765px) {
  .smallsize{
    display: none;
  }
  .navbarside{
    gap: 5px;
  }
  

  .button-menu{
    margin-right: -100px;
    margin-left: 300px;
    margin-top: -25px;
  }
  .total{
    margin-left: -220px;
    padding-left: 50px;
    
  }
  .home-smalll{
    margin-top: 30px;
  }
  .home-small{
    margin-left: 5px;
  }
  .down{
    margin-bottom: 40px;
  }
  .total li{
    margin-bottom: 30px;
    margin-left: 20px;
  }
  .menu-slide {
    transition: max-height 0.3s ease-in-out;
    max-height: 0;
    overflow: hidden;
  }
  
  .menu-slide.block {
    max-height: 500px; /* Set this value to a height that accommodates your menu items */
  }
  footer{
    display: block;
  }
  .copyright{
    display: none;
  }
  .linkdesktop{
    display: none;
  }
  .footersecond{
    display: flex;
  }
  .donatesign{
    margin-left: -50px;
  }
  .total{
    margin-top: 50px;
    margin-left: -290px;
  }
  .difficulties{
    display: none;
  }
  .sereniaisscreen{
    display: none;
  }
  .communityhome{
    margin-top: -70px;
    margin-bottom: -70px;
  }
  .relaxmood {
    background-image: url(images/Female.avif);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 1.7; /* Adjust the opacity level as desired */
  }
  .relaxmood h1{
    margin-top: 0px;
  }
  .relaxmood p{
    color:white;
  }
  .smallscreensidenav{
    display: none;
  }
        
  .second{
    display: none;
  }
  .visible {
    animation: popUp 3s forwards;
  }
  /* Add this CSS to your stylesheet */
@keyframes popUp {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
}

@property --angle {
  syntax: '<angle>';
  initial-value: 0deg;
  inherits: false;
}

@keyframes float {
  0% {
    transform: translate(0, 0) rotate(0deg) scale(1);
    opacity: 0;
  }
  25% {
    transform: translate(20px, -50px) rotate(90deg) scale(1.1);
    opacity: 1;
  }
  50% {
    transform: translate(-10px, -100px) rotate(180deg) scale(1);
    opacity: 0.8;
  }
  75% {
    transform: translate(-30px, -150px) rotate(270deg) scale(0.9);
    opacity: 0.4;
  }
  100% {
    transform: translate(0, -200px) rotate(360deg) scale(0.8);
    opacity: 0;
  }
}

.animate-float {
  animation: float var(--animation-duration, 10s) ease-in-out infinite;
}

/* Add smooth transition for heart hover effect */
.heart-hover {
  transition: transform 0.3s ease-in-out;
}

.heart-hover:hover {
  transform: scale(1.2);
}